import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA PETERNAK------
    findPeternak(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/peternak/find", { params: credentials })
    },
    getPeternak(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/peternak", { params: credentials })
    },
    getPeternakSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/peternak/single", { params: credentials })
    },
    getPeternakEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/peternak/edit", { params: credentials })
    },
    addPeternak(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/peternak", credentials)
    },
    addNasionalPeternak(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/peternak/nasional", credentials)
    },
    updatePeternak(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/peternak", credentials)
    },
    deletePeternak(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/peternak", { data: credentials })
    },

    // -----API DATA FARM PETERNAK------
    getPeternakFarm(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/peternak/farm", { params: credentials })
    },
    getPeternakFarmEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/master/peternak/farm/edit", { params: credentials })
    },
    addPeternakFarm(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/master/peternak/farm", credentials)
    },
    updatePeternakFarm(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/master/peternak/farm", credentials)
    },
    deletePeternakFarm(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/master/peternak/farm", { data: credentials })
    },
}