<template>
  <div id="master-data-peternak-edit" data-app>
    
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/master-data/peternak"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              class="nav-link active cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              Edit Data
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div v-if="dataOri">
                <ValidationObserver ref="formAdd">
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Kategori Usaha</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Kategori Usaha" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-peternak-1" 
                                  label-for="input-peternak-1"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-select2
                                    id="input-peternak-1"
                                    v-model="form.kategori"
                                    :options="opsKategoriUsaha"
                                    required
                                    variant="solid"
                                    size="lg"
                                    placeholder="Silahkan pilih Kategori Usaha"
                                    :state="valid"
                                  ></b-form-select2>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  <!--begin::Form Group-->
                  <div v-if="identitas && identitas == 'NIB'" class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Nama Poultry Shop</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider :name="`Nama Poultry Shop`" rules="required|min:5|max:50" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-peternak-2" 
                                  label-for="input-peternak-2"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-input
                                  autocomplete="off"
                                    id="input-peternak-2"
                                    v-model="form.nama"
                                    class="form-control form-control-solid"
                                    required
                                    size="lg"
                                    :placeholder="`Nama Poultry Shop`"
                                    :state="valid"
                                    ></b-form-input>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  <!--begin::Form Group-->
                  <div v-if="identitas && identitas == 'NIB'" class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">NIB</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider :name="`NIB`" rules="required|numeric|digits:13" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-peternak-3" 
                                  label-for="input-peternak-3"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-input
                                  autocomplete="off"
                                    id="input-peternak-3"
                                    v-model="form.nib"
                                    class="form-control form-control-solid"
                                    required
                                    size="lg"
                                    :placeholder="`NIB Poultry Shop`"
                                    :state="valid"
                                    ></b-form-input>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  
                  <!--begin::Form Group-->
                  <div v-if="identitas && identitas == 'NIB'" class="separator separator-dashed my-5 mt-15"></div>

                  <div v-if="identitas">
                    <!--begin::Form Group-->
                    <div class="row">
                        <label class="col-xl-3"></label>
                        <div class="col-lg-9 col-xl-7">
                            <h5 v-if="identitas == 'NIB'" class="font-weight-bold mb-6">Data Penanggung Jawab:</h5>
                            <h5 v-else class="font-weight-bold mb-6">Data Peternak:</h5>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label">Nama</label>
                        <div class="col-lg-9 col-xl-7">
                            <validationProvider name="Nama Lengkap" rules="required|min:5|max:50" v-slot="{ valid, errors }">
                                <b-form-group
                                    id="input-group-peternak-6" 
                                    label-for="input-peternak-6"
                                    :invalid-feedback="errors[0]"
                                    :description="identitas == 'NIB' ? 'Isikan penanggung jawab peternak seperti manager atau level yang lebih tinggi.' : ''"
                                    class="mb-0"
                                    :state="valid">
                                    <b-form-input
                                    autocomplete="off"
                                      id="input-peternak-6"
                                      v-model="form.pj"
                                      class="form-control form-control-solid"
                                      required
                                      size="lg"
                                      placeholder="Nama Lengkap"
                                      :state="valid"
                                      ></b-form-input>
                                </b-form-group>
                            </validationProvider>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label">NIK</label>
                        <div class="col-lg-9 col-xl-7">
                            <validationProvider name="NIK" rules="required|numeric|digits:16" v-slot="{ valid, errors }">
                                <b-form-group
                                    id="input-group-peternak-7" 
                                    label-for="input-peternak-7"
                                    :invalid-feedback="errors[0]"
                                    class="mb-0"
                                    :state="valid">
                                    <b-form-input
                                    autocomplete="off"
                                      id="input-peternak-7"
                                      v-model="form.pj_nik"
                                      class="form-control form-control-solid"
                                      required
                                      size="lg"
                                      placeholder="Nomor Induk Kependudukan"
                                      :state="valid"
                                      ></b-form-input>
                                </b-form-group>
                            </validationProvider>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
                        <div class="col-lg-9 col-xl-7">
                            <validationProvider name="Email" rules="email" v-slot="{ valid, errors }">
                                <b-form-group
                                    id="input-group-peternak-8" 
                                    label-for="input-peternak-8"
                                    :invalid-feedback="errors[0]"
                                    class="mb-0"
                                    :state="valid">
                                    <b-form-input
                                    autocomplete="off"
                                      id="input-peternak-8"
                                      v-model="form.pj_email"
                                      class="form-control form-control-solid"
                                      size="lg"
                                      placeholder="Email"
                                      :state="valid"
                                      ></b-form-input>
                                </b-form-group>
                            </validationProvider>
                        </div>
                    </div>
                    <!--begin::Form Group-->
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label">No. Ponsel</label>
                        <div class="col-lg-9 col-xl-7">
                            <validationProvider name="No. Ponsel" rules="required|numeric|max:20" v-slot="{ valid, errors }">
                                <b-form-group
                                    id="input-group-peternak-9" 
                                    label-for="input-peternak-9"
                                    :invalid-feedback="errors[0]"
                                    class="mb-0"
                                    :state="valid">
                                    <b-input-group prepend="+62" class="border-none">
                                      <b-input 
                                        autocomplete="off"
                                        id="input-peternak-9" 
                                        v-model="form.pj_telp"
                                        class="form-control form-control-solid"
                                        required
                                        size="lg"
                                        placeholder="Nomor Ponsel"
                                        :state="valid"
                                    ></b-input>
                                    </b-input-group>
                                </b-form-group>
                                <span class="form-text text-muted">Angka nol (0) didepan tidak ditulis.</span>
                            </validationProvider>
                        </div>
                    </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="separator separator-dashed my-5 mt-15"></div>
                  <!--begin::Form Group-->
                  <div class="row">
                      <label class="col-xl-3"></label>
                      <div class="col-lg-9 col-xl-7">
                          <h5 class="font-weight-bold mb-6">Lokasi:</h5>
                      </div>
                  </div>              
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Provinsi</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Provinsi" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-peternak-10" 
                                  label-for="input-peternak-10"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-select2
                                  id="input-peternak-10"
                                  v-model="form.provinsi"
                                  required
                                  variant="solid"
                                  size="lg"
                                  :options="opsProvinsi"
                                  placeholder="Silahkan pilih Provinsi"
                                  :state="valid"
                                  ></b-form-select2>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>                        
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Kabupaten</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Kabupaten" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-peternak-11" 
                                  label-for="input-peternak-11"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-select2
                                  id="input-peternak-11"
                                  v-model="form.kabupaten"
                                  required
                                  variant="solid"
                                  size="lg"
                                  :options="opsKabupaten"
                                  placeholder="Silahkan pilih Kabupaten"
                                  :state="valid"
                                  ></b-form-select2>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>          
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Kecamatan</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Kecamatan" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-peternak-12" 
                                  label-for="input-peternak-12"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-select2
                                  id="input-peternak-12"
                                  v-model="form.kecamatan"
                                  required
                                  variant="solid"
                                  size="lg"
                                  :options="opsKecamatan"
                                  placeholder="Silahkan pilih Kecamatan"
                                  :state="valid"
                                  ></b-form-select2>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Desa</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Desa" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-peternak-13" 
                                  label-for="input-peternak-13"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-select2
                                  id="input-peternak-13"
                                  v-model="form.desa"
                                  required
                                  variant="solid"
                                  size="lg"
                                  :options="opsDesa"
                                  placeholder="Silahkan pilih Desa"
                                  :state="valid"
                                  ></b-form-select2>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Alamat</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Alamat" rules="required|max:255" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-peternak-14" 
                                  label-for="input-peternak-14"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-input
                                  autocomplete="off"
                                  id="input-peternak-14"
                                  v-model="form.alamat"
                                  class="form-control form-control-solid"
                                  required
                                  size="lg"
                                  placeholder="Alamat"
                                  :state="valid"
                                  ></b-form-input>
                              </b-form-group>
                              <span class="form-text text-muted">Tulis nama jalan, nomor rumah, nomor kompleks, nama gedung, lantai atau nomor unit.</span>
                          </validationProvider>
                      </div>
                  </div>
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Tag Lokasi</label>
                      <div class="col-lg-9 col-xl-7">
                        <b-form-group
                            id="input-group-peternak-15" 
                            label-for="gmaps"
                            invalid-feedback="Tag lokasi wajib diisi"
                            class="mb-0"
                            :state="cekgmaps">
                            <div style="display: none">
                              <input
                                id="pac-input"
                                ref="alamat"
                                class="controls"
                                type="text"
                                placeholder="Enter a location"
                              />
                            </div>

                            <div class="rounded iframe-content">
                              <div id="gmaps" ref="goomaps"></div>
                            </div>
                        </b-form-group>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="separator separator-dashed my-5 mt-15"></div>
                  <!--begin::Form Group-->
                  <div class="row">
                      <label class="col-xl-3"></label>
                      <div class="col-lg-9 col-xl-7">
                          <h5 class="font-weight-bold mb-3">Komoditas:</h5>
                      </div>
                  </div>   
                  <b-row v-for="(rinc, index) in form.usaha" :key="index">
                    <b-col cols="10" class="pb-0 pt-0">
                      <h5 class="mt-4 text-muted">Rincian {{index + 1}}</h5>
                    </b-col>
                    <b-col cols="2" class="text-right">
                      <b-button v-show="(form.usaha.length > 1) ? true : false" @click="deleteKomoditas(index)" size="sm" variant="link" class="text-hover-primary">
                        <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                      </b-button>
                    </b-col>
                    <b-col cols="12" sm="4">
                      <validationProvider :name="`Rin ${index+1} Komoditas`" rules="required" v-slot="{ valid, errors }">
                        <b-form-group 
                        :id="`input-group-5-${index}-1`" 
                        label="Komoditas:" 
                        :label-for="`input-5-${index}-1`"
                        :invalid-feedback="errors[0]"
                        :state="valid">    
                          <b-form-select2
                            :id="`input-5-${index}-1`"
                            v-model="rinc.komoditas"
                            variant="solid"
                            size="lg"
                            @change="onChangeKomoditas(index)"
                            :options="opsTernakKomoditas"
                            required
                            placeholder="Silahkan pilih Komoditas"
                            :state="valid"
                          ></b-form-select2>
                        </b-form-group>
                      </validationProvider>
                    </b-col>
                    <b-col cols="12" sm="4">
                      <validationProvider :name="`Rin ${index+1} Tipe`" rules="required|min_value:1|max_value:9999999999" v-slot="{ valid, errors }">
                        <b-form-group 
                        :id="`input-group-5-${index}-2`" 
                        label="Tipe:" 
                        :label-for="`input-5-${index}-2`"
                        :invalid-feedback="errors[0]"
                        :state="valid">          
                          <b-form-select2
                            :id="`input-5-${index}-2`"
                            v-model="rinc.tipe"
                            variant="solid"
                            size="lg"
                            :options="getTipeByKomoditas(rinc.komoditas)"
                            required
                            placeholder="Silahkan pilih Tipe"
                            :state="valid"
                          ></b-form-select2>
                        </b-form-group>
                      </validationProvider>
                    </b-col>
                    <b-col cols="12" sm="4">
                      <validationProvider :name="`Rin ${index+1} Jenis Usaha`" rules="required" v-slot="{ valid, errors }">
                        <b-form-group 
                        :id="`input-group-5-${index}-3`" 
                        label="Jenis Usaha:" 
                        :label-for="`input-5-${index}-3`"
                        :invalid-feedback="errors[0]"
                        :state="valid">
                          <b-form-select2
                            :id="`input-5-${index}-3`"
                            v-model="rinc.usaha"
                            variant="solid"
                            size="lg"
                            :options="getUsahaByKomoditas(rinc.komoditas)"
                            required
                            placeholder="Silahkan pilih Jenis Usaha"
                            :state="valid"
                          ></b-form-select2>
                        </b-form-group>
                      </validationProvider>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" class="text-right">
                      <b-button @click="addKomoditas" variant="link" class="text-hover-primary">
                        <i class="flaticon2-plus-1 p-0"></i>
                        Add Komoditas
                      </b-button>
                    </b-col>
                  </b-row>

                  <div class="mt-10">
                    <button 
                    @click="onSubmit" 
                    ref="kt_submit"
                    class="btn btn-primary font-weight-bold">
                      <i class="la la-save"></i>
                      Perbarui
                    </button>

                    &nbsp;

                    <button
                    @click="onReset"
                    class="btn btn-clean font-weight-bold">
                      <i class="la la-recycle"></i>
                      Reset
                    </button>
                  </div>
                  
                </b-form>
                </ValidationObserver>  
              </div>
              <div v-else>
                <BulletListLoader></BulletListLoader>
              </div>             
            </b-tab>
          </b-tabs>
        </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Loader } from '@googlemaps/js-api-loader';
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MasterService from "@/core/services/api/master/master.service";
import WilayahService from "@/core/services/api/master/wilayah.service";
import PeternakService from "@/core/services/api/master/peternak.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-peternak-edit",
  props: {
    idP: {
      required: true
    }
  },
  components: {
    BulletListLoader
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      dataOri: null,
      form: {
        peternak: this.idP,
        nama: null,
        nib: null,
        kategori: null,
        usaha: [{
          komoditas: null,
          tipe: null,
          usaha: null
        }],
        pj: null,
        pj_nik: null,
        pj_email: null,
        pj_telp: null,
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
        geotag: null
      },
      gooMaps: null,
      gooWindow: null,
      gooMarker: null,
      opsKategoriUsaha: [{ 
        text: 'Silahkan pilih Kategori Usaha', 
        value: null 
      }],
      opsTernakKomoditas: [{
        value: null,
        text: "Silahkan pilih Komoditas"
      }],
      opsTernakTipe: [{
        value: null,
        text: "Silahkan pilih Tipe"
      }],
      opsUsaha: [{
        value: null,
        text: "Silahkan pilih Jenis Usaha"
      }],
      opsProvinsi: [{
        value: null,
        text: "Silahkan pilih Provinsi"
      }],
      opsKabupaten: [{
        value: null,
        text: "Silahkan pilih Kabupaten"
      }],
      opsKecamatan: [{
        value: null,
        text: "Silahkan pilih Kecamatan"
      }],
      opsDesa: [{
        value: null,
        text: "Silahkan pilih Desa"
      }],
      show: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    identitas() {
      if (!this.form.kategori || this.opsKategoriUsaha.length < 2) return null
      const cekData = this.opsKategoriUsaha.find(o => o.value === this.form.kategori)
      return cekData.identitas
    },
    cekgmaps() {
      if (this.form.geotag) return true
      return false
    },
    opsGroupTipe () {
      return this.opsTernakTipe.reduce((acc, obj) => {
        const property = obj['komoditas'];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    },
    opsGroupUsaha () {
      return this.opsUsaha.reduce((acc, obj) => {
        const property = obj['komoditas'];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    }
  },
  watch: {
    'form.provinsi' (val, old) {
      if (val != old) {
        if (val == null) {
          this.opsKabupaten= [{
            value: null,
            text: "Silahkan pilih Kabupaten"
          }]
          return
        }

        if (this.form.kabupaten && val != this.form.kabupaten.toString().substring(0, 2)) {
          this.form.kabupaten = null
          this.form.kecamatan = null
          this.form.desa = null
        }
        this.getKabupaten({ provinsi: val, aks: 1 })
        // this.centerMaps(val, 'provinsi')
        this.deleteMarker()
      }
    },
    'form.kabupaten' (val, old) {
      if (val != old) {
        if (val == null) {
          this.opsKecamatan= [{
            value: null,
            text: "Silahkan pilih Kecamatan"
          }]
          return
        }
        if (this.form.kecamatan && val != this.form.kecamatan.toString().substring(0, 4)) {
          this.form.kecamatan = null
          this.form.desa = null
        }
        this.getKecamatan({ kabupaten: val, aks: 1 })
        this.centerMaps(val, 'kabupaten')
        this.deleteMarker()
      }
    },
    'form.kecamatan' (val, old) {
      if (val != old) {
        if (val == null) {
          this.opsDesa= [{
            value: null,
            text: "Silahkan pilih Desa"
          }]  
          return
        }
        if (this.form.desa && val != this.form.desa.toString().substring(0, 6)) {
          this.form.desa = null
        }
        this.getDesa({ kecamatan: val, aks: 1 })
        // this.centerMaps(val, 'kecamatan')
        this.deleteMarker()
      }
    },
    'form.desa' (val, old) {
      if (val != old) {
        // this.centerMaps(val, 'desa')
        this.deleteMarker()
      }
    },
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },
    
    addKomoditas(){
      this.form.usaha.push({
            komoditas: null,
            tipe: null,
            usaha: null
          })
    },
    deleteKomoditas(index){
      if (this.form.usaha.length > 1) {
        this.form.usaha.splice(index,1);
      }
      else {
        this.form.usaha = [
          {
            komoditas: null,
            tipe: null,
            usaha: null
          }
        ]
      }
    },
    onChangeKomoditas(index) {
      this.form.usaha[index].tipe = null
      this.form.usaha[index].usaha = null
    },
    centerMaps (kode, ting) {
      if (!this.loading) return
      let latlng = {
        lat: -6.200000,
        lng: 106.816666
      }
      let zoom = 11;

      if (ting == 'provinsi') {
        const getDT = this.opsProvinsi.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        zoom = 12;
      }
      else if (ting == 'kabupaten') {
        const getDT = this.opsKabupaten.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        zoom = 13;
      }
      else if (ting == 'kecamatan') {
        const getDT = this.opsKecamatan.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        zoom = 13;
      }
      else if (ting == 'desa') {
        const getDT = this.opsDesa.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        zoom = 13;
      }
      else {
        latlng = { lat: -6.200000, lng: 106.816666 }
      }

      this.gooMaps.setZoom(zoom);
      this.gooMaps.setCenter(latlng);
    },
    deleteMarker() {
      if (!this.loading) return
      this.gooMarker.setMap(null);
      this.form.geotag = null
    },
    getTipeByKomoditas(id) {
      let data = [{ text: 'Silahkan pilih Tipe', value: null }]
      if(!id) return data
      return [...data, ...this.opsGroupTipe[id]]
    },
    getUsahaByKomoditas(id) {
      let data = [{ text: 'Silahkan pilih Tipe', value: null }]
      if(!id || this.opsGroupUsaha.length < 1) return data
      return [...data, ...this.opsGroupUsaha[id]]
    },
    
    getTernakKomoditas() {
      MasterService.getTernakKomoditas()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakKomoditas = [{
                  value: null,
                  text: "Silahkan pilih Komoditas"
                }]
                return;
            }

            this.opsTernakKomoditas = res.data.data
            this.opsTernakKomoditas.push({
              value: null,
              text: "Silahkan pilih Komoditas"
            })
        })
        .catch((err) => {
          this.opsTernakKomoditas = [{
            value: null,
            text: "Tidak ada Komoditas"
          }]
          ErrorService.status(err)
        })
    },
    getTernakTipe() {
      MasterService.getTernakTipe({ child: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakTipe = [{
                  value: null,
                  text: "Silahkan pilih Tipe"
                }]
                return;
            }

            this.opsTernakTipe = res.data.data
            this.opsTernakTipe.push({
              value: null,
              text: "Silahkan pilih Tipe"
            })
        })
        .catch((err) => {
          this.opsTernakTipe = [{
            value: null,
            text: "Tidak ada Tipe"
          }]
          ErrorService.status(err)
        })
    },
    getTipeUsaha() {
      MasterService.getTipeUsaha({ child: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsUsaha = [{
                  value: null,
                  text: "Silahkan pilih Jenis Usaha"
                }]
                return;
            }

            this.opsUsaha = res.data.data
            this.opsUsaha.push({
              value: null,
              text: "Silahkan pilih Jenis Usaha"
            })
        })
        .catch((err) => {
          this.opsUsaha = [{
            value: null,
            text: "Tidak ada Jenis Usaha"
          }]
          ErrorService.status(err)
        })
    },
    getKategoriUsaha() {
      MasterService.getKategoriUsaha({ komoditas: 11040021 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKategoriUsaha = [{
                  value: null,
                  text: "Silahkan pilih Kategori Usaha"
                }]
                return;
            }

            this.opsKategoriUsaha = res.data.data
            this.opsKategoriUsaha.push({
              value: null,
              text: "Silahkan pilih Kategori Usaha"
            })
        })
        .catch((err) => {
          this.opsKategoriUsaha = [{
            value: null,
            text: "Tidak ada Kategori Usaha"
          }]
          ErrorService.status(err)
        })
    },
    getProvinsi() {
      WilayahService.getProvinsi({ aks: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsProvinsi = [{
                  value: null,
                  text: "Silahkan pilih Provinsi"
                }]
                return;
            }

            this.opsProvinsi = res.data.data
            this.opsProvinsi.push({
              value: null,
              text: "Silahkan pilih Provinsi"
            })
        })
        .catch((err) => {
          this.opsProvinsi = [{
            value: null,
            text: "Tidak ada Provinsi"
          }]
          ErrorService.status(err)
        })
    },
    getKabupaten(param) {
      const fault = [{
        value: null,
        text: "Silahkan pilih Kabupaten"
      }]

      if (!param) {
        this.opsKabupaten = fault 
        return;
      }
      
      WilayahService.getKabupaten(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKabupaten = fault 
                return;
            }

            this.opsKabupaten = [ ...res.data.data, ...fault ]
        })
        .catch((err) => {
          this.opsKabupaten = [{
            value: null,
            text: "Tidak ada Kabupaten"
          }]
          ErrorService.status(err)
        })
    },
    getKecamatan(param) {
      const fault = [{
        value: null,
        text: "Silahkan pilih Kecamatan"
      }]

      if (!param) {
        this.opsKecamatan = fault 
        return;
      }

      WilayahService.getKecamatan(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKecamatan = fault 
                return;
            }

            this.opsKecamatan = [ ...res.data.data, ...fault ]
        })
        .catch((err) => {
          this.opsKecamatan = [{
            value: null,
            text: "Tidak ada Kecamatan"
          }]
          ErrorService.status(err)
        })
    },
    getDesa(param) {
      const fault = [{
        value: null,
        text: "Silahkan pilih Desa"
      }]

      if (!param) {
        this.opsDesa = fault 
        return;
      }

      WilayahService.getDesa(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsDesa = fault 
                return;
            }

            this.opsDesa = [ ...res.data.data, ...fault ]
        })
        .catch((err) => {
          this.opsDesa = [{
            value: null,
            text: "Tidak ada Desa"
          }]
          ErrorService.status(err)
        })
    },
    initMaps() {
      let mapView = this.$refs.goomaps
      let input = this.$refs.alamat;
      let markerconfig = {}
      let latlng = this.form.geotag || {
        lat: -6.200000,
        lng: 106.816666
      }

      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          libraries: ["places"]
      });

      loader.load()
        .then((google) => {
          this.gooMaps = new google.maps.Map(mapView, {
            center: latlng,
            zoom: 17,
            maId: "f7a6bf3173b56abc",
            controlSize: 25,
            mapTypeId: "roadmap",
            mapTypeControlOptions: {
              mapTypeIds: [],
            },
            streetViewControl: false
          });

          this.gooWindow = new google.maps.InfoWindow({ content: "Lokasi dipilih" });

          markerconfig.map = this.gooMaps
          markerconfig.position = this.form.geotag 

          this.gooMarker = new google.maps.Marker(markerconfig);

          this.gooMaps.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

          const autocomplete = new google.maps.places.Autocomplete(input);
          autocomplete.bindTo("bounds", this.gooMaps);
          autocomplete.setFields(["place_id", "geometry"]);

          if (!this.loading) this.loading = true

          this.gooMaps.addListener("click", (mapsMouseEvent) => {
            this.gooWindow.close();
            this.gooMarker.setMap(null);

            this.gooMarker = new google.maps.Marker({ 
              position: mapsMouseEvent.latLng,
              map: this.gooMaps 
            });
            
            this.gooMarker.setVisible(true);
            this.gooWindow.open(this.gooMaps, this.gooMarker);

            this.form.geotag = mapsMouseEvent.latLng.toJSON()
          });

          autocomplete.addListener("place_changed", () => {
            this.gooWindow.close();
            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
              return;
            }

            if (place.geometry.viewport) {
              this.gooMaps.fitBounds(place.geometry.viewport);
            } else {
              this.gooMaps.setCenter(place.geometry.location);
              this.gooMaps.setZoom(17);
            }
            
            this.gooMarker.setPlace({
              placeId: place.place_id,
              location: place.geometry.location,
            });

            this.gooMarker.setVisible(true);
            this.gooWindow.open(this.gooMaps, this.gooMarker);
            
            this.form.geotag = place.geometry.location.toJSON()
          });

        })      
        .catch(err => {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Maps gagal dimuat',
            })
          }
        )
    },
    getData () {
      PeternakService.getPeternakEdit({ peternak: this.idP })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            const datta = { ...res.data.data, geotag: JSON.parse(res.data.data.geotag) }
            this.dataOri = JSON.stringify(datta)
            this.form = datta
            this.$nextTick(() => {
              setTimeout(() => {
                this.initMaps()              
              }, 500);
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if(!this.identitas) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return
        }

        if (this.identitas == 'NIK') {
          this.form.nama = null
          this.form.nib = null
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            PeternakService.updatePeternak({ ...this.form, identitas: this.identitas })
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)

                    if (res.data.status) {
                      this.dataOri = JSON.stringify(this.form)
                    }

                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form = JSON.parse(this.dataOri)
      
      this.loading = false
      this.gooMaps = null
      this.gooMarker = null
      this.gooWindow = null

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
        setTimeout(() => {
          this.initMaps()        
        }, 500);
      })
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Peternak" }]);
    if (!this.currentUser.menu.peternak) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getTernakKomoditas()
    this.getTernakTipe()
    this.getProvinsi()
    await this.getTipeUsaha()
    await this.getKategoriUsaha()
    await this.getData()
  },
};
</script>
<style lang="scss">
  #master-data-peternak-edit {
    .iframe-content {
      height: 400px;
      overflow: auto;
      
      #gmaps, #gmapsfarm {
        height: 100%;
      }
    }

    .controls {
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid transparent;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      box-sizing: border-box;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 300;
      height: 29px;
      margin-top: 10px;
      outline: none;
      padding: 0 11px 0 13px;
      text-overflow: ellipsis;
    }

    .controls:focus {
      border-color: #4d90fe;
    }
  }
</style>
